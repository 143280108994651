<template>
  <st-box class="mt-24">
    <div v-if="isLoaded" class="flex items-center gap-8 rounded-3xl px-20 py-16 bg-[#F2F6FF]">
      <span
        role="text4"
        class="!font-bold rounded-3xl py-2 px-12 bg-brand-primary text-surface-elevation-1"
      >
        {{
          $t(
            'studio.prj_prod.build_mgmt.build_detail.post_release_build_update.case_self_review.badge_live_apply_y'
          )
        }}
      </span>
      <h3 role="title7" class="!font-bold text-on-surface-elevation-2">
        {{ applyLiveInfo?.liveBuildNo || '' }}
      </h3>
      <span role="text3" class="font-medium text-on-surface-elevation-2">
        {{ applyLiveInfo?.liveBuildDescription || '' }}
      </span>
    </div>
    <div class="flex flex-col gap-8 mt-24">
      <h3 role="title6" class="!font-bold text-on-surface-elevation-2">
        {{ applyLiveInfo?.applyLiveBuildNo || '' }}
      </h3>
      <p role="text3" class="font-medium text-on-surface-elevation-2">
        {{ applyLiveInfo?.applyLiveBuildDescription || '' }}
      </p>
    </div>
    <div
      v-if="isShowCheckbox"
      class="flex flex-col gap-12 border-t-1 border-solid border-border pt-32 mt-32"
    >
      <st-form-title
        :formTitle="
          $t(
            'studio.prj_prod.build_mgmt.build_detail.post_release_build_update.case_self_review.updated_item_check'
          )
        "
        required
      />
      <div class="flex flex-col gap-8">
        <checkbox-group
          name="live.selectedProductsId"
          :options="productOpts"
          size="sm"
          class="flex flex-col gap-12"
          :optionProps="{
            align: 'middle',
            size: 'sm'
          }"
        />
      </div>
    </div>
    <div v-else class="flex flex-col gap-12 border-t-1 border-solid border-border mt-32"></div>
    <div v-if="showButtonSection" class="flex flex-col gap-12 mt-32">
      <st-form-title
        :formTitle="
          $t(
            'studio.prj_prod.build_mgmt.build_detail.post_release_build_update.case_self_review.info_change_report_guide'
          )
        "
        required
      />
      <s-button
        variant="primary"
        size="md"
        class="self-start !min-w-160"
        @click="handleUsageTiersClick"
      >
        {{
          $t(
            'studio.prj_prod.build_mgmt.build_detail.post_release_build_update.case_self_review.info_change_report_rating_menu_dir_btn'
          )
        }}
      </s-button>
      <span
        v-if="buildDetail?.releaseStatus === RELEASE_STATUS_BUILD.VERIFY_UNDER_REVIEW"
        role="text3"
        class="font-medium text-tint-red-a400"
      >
        {{
          $t(
            'studio.prj_prod.build_mgmt.build_detail.post_release_build_update.case_self_review.info_change_report_stt_under_review'
          )
        }}
      </span>
    </div>

    <div v-if="showDatetimeSection" class="flex flex-col gap-12 mt-32">
      <st-form-title
        :formTitle="
          $t(
            'studio.prj_prod.build_mgmt.build_detail.post_release_build_update.case_self_review.change_report_complete.build_apply_date'
          )
        "
        required
      />
      <div class="flex flex-col gap-8">
        <radio-group
          v-model="liveDate"
          :options="optionsApplyLive"
          rules="required"
          class="flex flex-col gap-8"
          size="sm"
          @update:modelValue="handleRadioLiveChange"
        />

        <template v-if="liveDate === Confirmation.NO">
          <datetime-picker
            name="dateTimeBuild"
            showUtc
            :modelValue="selectedDate"
            @update:modelValue="handleDateTimeChange"
          />
          <p v-if="dateTimeError" class="flex gap-2 text-xs leading-xs text-error mt-4">
            <s-icon icon="ic-v2-state-warning-circle-fill" size="xl" class="flex" />
            <s-text role="cap1">{{ dateTimeError }}</s-text>
          </p>
        </template>
      </div>
    </div>
  </st-box>
  <div class="flex justify-center mt-40">
    <s-button
      variant="primary"
      size="lg"
      class="!min-w-160"
      :isDisabled="isButtonDisabled"
      @click="handleApplyLive"
    >
      {{ $t('studio.common.live_apply_btn') }}
    </s-button>
  </div>
</template>
<script lang="ts" setup>
import { storeToRefs } from 'pinia';
import { useFieldValue, useForm, useSetFieldValue } from 'vee-validate';
import { computed, ref, shallowRef, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';

import { definePageMeta } from '@/.nuxt/imports';
import { createApplyLiveReleaseBuildApi } from '@/apis/build.api';
import { generateMetaFileApi } from '@/apis/launch-settings.api';
import { fetchRatingsProductsApi, postRatingsRevisionProductsApi } from '@/apis/rating.api';
import ConfirmReleaseBuild from '@/components/build/dialog/confirm-release-build.vue';
import StBox from '@/components/common/st-box.vue';
import StFormTitle from '@/components/common/st-form-title.vue';
import RatingSurveyDialog from '@/components/rating/rating-survey-dialog.vue';
import CheckboxGroup from '@/components/validation/checkbox-group.vue';
import DatetimePicker from '@/components/validation/datetime-picker.vue';
import RadioGroup from '@/components/validation/radio-group.vue';
import { useApp } from '@/composables/useApp';
import { showAlert, showDialog } from '@/composables/useDialog';
import { API_SUCCESS_STATUS_CODE } from '@/constants/api.const';
import {
  APPLY_LIVE_RELEASE_TYPE,
  RELEASE_STATUS_BUILD,
  REVISION_TYPE_TO_TRANSLATION
} from '@/constants/build.const';
import { COMMON_ERROR_MESSAGE_KEY, STATUS_CODE } from '@/constants/error.const';
import { ROLE_ID } from '@/constants/permission.const';
import { PRODUCT_TYPE_DETAIL } from '@/constants/products.const';
import { PRODUCT_RATING_URL } from '@/constants/url.const';
import { Confirmation } from '@/enums/common.enum';
import { RatingPageMode, RatingTypeStatus, RevisionType, SurveyDialogType, VerifyStatus } from '@/enums/rating.enum';
import { useBuildStore } from '@/stores/build.store';
import useProductStore from '@/stores/product.store';
import type { ApplyLiveReleaseBuildParams } from '@/types/build/build.type';
import type { ErrorResponse } from '@/types/common/common.type';
import type { FormOption } from '@/types/common/form.type';
import type { PostRatingRevisionProductRequest } from '@/types/rating/rating.request.type';
import { mapApiValueToRevisionType, mapRevisionTypeToApiValue } from '@/utils/build.util';
import { redirectTo, showCommonErrorDialog } from '@/utils/common.util';

definePageMeta({
  middleware: ['build-live-middleware', 'handle-product-data-middleware'],
  pageTitle: 'studio.prj_prod.build_mgmt.build_detail.post_release_build_update.title'
});

const { checkProductPermission } = useApp();

const { locale } = useI18n();
const { t } = useI18n();
const route = useRoute();
const buildStore = useBuildStore();
const { buildDetail, applyLiveInfo } = storeToRefs(buildStore);

const productStore = useProductStore();
const { currentProductDetailType } = storeToRefs(productStore);

const liveDate = ref<string>(Confirmation.YES);
const buildId = route.params.buildId as string;
const productId = route.params.productId as string;

const showInfoChangeReport = ref(false);
const dateTimeError = ref<string>('');
const defaultDateTime = computed(() => {
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  tomorrow.setHours(0, 0, 0, 0);
  return tomorrow;
});
const selectedDate = ref<Date | string>(defaultDateTime.value);

const { handleSubmit, meta } = useForm({
  initialValues: {
    dateTimeBuild: defaultDateTime.value,
    live: { selectedProductsId: [] }
  }
});

const selectedProductsId = useFieldValue<string[]>('live.selectedProductsId');
const setSelectedProductsId = useSetFieldValue<string[]>('live.selectedProductsId');
const dateTimeBuildValue = useFieldValue<Date | string>('dateTimeBuild');
const setDateTimeBuildValue = useSetFieldValue<Date | string>('dateTimeBuild');

watch(
  selectedProductsId,
  (newValue: any) => {
    if (newValue.length === 1 && newValue[0] === RevisionType.BugFix) {
      showInfoChangeReport.value = false;
      return;
    }

    showInfoChangeReport.value = true;
  },
  { immediate: true }
);

const validateDateTime = (value: string | Date | undefined) => {
  if (!value) {
    dateTimeError.value = t(
      'studio.prj_prod.build_mgmt.build_detail.post_release_build_update.case_self_review.change_report_complete.schedule_req_msg1'
    );
  } else {
    const now = new Date(); // Current date and time
    const selectedDate = new Date(value);

    // Compare full timestamp
    if (selectedDate.getTime() <= now.getTime()) {
      dateTimeError.value = t(
        'studio.prj_prod.build_mgmt.build_detail.post_release_build_update.case_self_review.change_report_complete.schedule_invalid_msg2'
      );
    } else {
      dateTimeError.value = '';
    }
  }
};

watch(() => dateTimeBuildValue.value, validateDateTime, { immediate: true });

const handleRadioLiveChange = (value: string) => {
  if (value === Confirmation.NO) {
    setDateTimeBuildValue(selectedDate.value);
    validateDateTime(selectedDate.value);
  }
};

const handleDateTimeChange = (value: string | Date | undefined) => {
  const newDate = parseDate(value);
  updateDateState(newDate);
};

const parseDate = (value: string | Date | undefined): Date => {
  if (value instanceof Date) {
    return value;
  }
  if (typeof value === 'string') {
    const parsedDate = new Date(value);
    return isNaN(parsedDate.getTime()) ? defaultDateTime.value : parsedDate;
  }
  return defaultDateTime.value;
};

const updateDateState = (date: Date) => {
  selectedDate.value = date;
  setDateTimeBuildValue(date);
  validateDateTime(date);
};

const init = async () => {
  if (!buildId || !buildDetail.value) {
    return;
  }

  const applyLiveInfoParams = {
    buildId: buildDetail.value.buildId,
    gameId: buildDetail.value.gameId
  };
  await buildStore.getApplyLiveReleaseBuild(applyLiveInfoParams);

  if (applyLiveInfo.value) {
    if (applyLiveInfo.value.releaseType === APPLY_LIVE_RELEASE_TYPE.SCHEDULED) {
      liveDate.value = Confirmation.NO;
      if (applyLiveInfo.value.scheduledStartAt) {
        const scheduledDate = new Date(applyLiveInfo.value.scheduledStartAt * 1000);
        selectedDate.value = scheduledDate;
        setDateTimeBuildValue(scheduledDate);
        validateDateTime(scheduledDate);
      }
    } else {
      liveDate.value = Confirmation.YES;
    }
  }

  if (applyLiveInfo.value && applyLiveInfo.value?.buildChangeOptions?.length > 0) {
    setSelectedProductsId(applyLiveInfo.value.buildChangeOptions.map(mapApiValueToRevisionType));
  }
};

const optionsApplyLive: FormOption[] = [
  {
    value: Confirmation.YES,
    label: t(
      'studio.prj_prod.build_mgmt.build_detail.post_release_build_update.case_self_review.change_report_complete.immed_apple'
    )
  },
  {
    value: Confirmation.NO,
    label: t(
      'studio.prj_prod.build_mgmt.build_detail.post_release_build_update.case_self_review.change_report_complete.schedule_apply'
    )
  }
];

const productOpts = computed(() => [
  {
    label: t(REVISION_TYPE_TO_TRANSLATION[RevisionType.BugFix]),
    value: RevisionType.BugFix,
    isDisabled: [
      RELEASE_STATUS_BUILD.VERIFY_UNDER_REVIEW,
      RELEASE_STATUS_BUILD.VERIFY_REVIEW_COMPLETED,
      RELEASE_STATUS_BUILD.COMING_SOON
    ].includes(buildDetail.value?.releaseStatus || '')
  },
  {
    label: t(REVISION_TYPE_TO_TRANSLATION[RevisionType.Background]),
    value: RevisionType.Background,
    isDisabled: [
      RELEASE_STATUS_BUILD.VERIFY_UNDER_REVIEW,
      RELEASE_STATUS_BUILD.VERIFY_REVIEW_COMPLETED,
      RELEASE_STATUS_BUILD.COMING_SOON
    ].includes(buildDetail.value?.releaseStatus || '')
  },
  {
    label: t(REVISION_TYPE_TO_TRANSLATION[RevisionType.Ui]),
    value: RevisionType.Ui,
    isDisabled: [
      RELEASE_STATUS_BUILD.VERIFY_UNDER_REVIEW,
      RELEASE_STATUS_BUILD.VERIFY_REVIEW_COMPLETED,
      RELEASE_STATUS_BUILD.COMING_SOON
    ].includes(buildDetail.value?.releaseStatus || '')
  },
  {
    label: t(REVISION_TYPE_TO_TRANSLATION[RevisionType.Billing]),
    value: RevisionType.Billing,
    isDisabled: [
      RELEASE_STATUS_BUILD.VERIFY_UNDER_REVIEW,
      RELEASE_STATUS_BUILD.VERIFY_REVIEW_COMPLETED,
      RELEASE_STATUS_BUILD.COMING_SOON
    ].includes(buildDetail.value?.releaseStatus || '')
  },
  {
    label: t(REVISION_TYPE_TO_TRANSLATION[RevisionType.Scenario]),
    value: RevisionType.Scenario,
    isDisabled: [
      RELEASE_STATUS_BUILD.VERIFY_UNDER_REVIEW,
      RELEASE_STATUS_BUILD.VERIFY_REVIEW_COMPLETED,
      RELEASE_STATUS_BUILD.COMING_SOON
    ].includes(buildDetail.value?.releaseStatus || '')
  },
  {
    label: t(REVISION_TYPE_TO_TRANSLATION[RevisionType.Character]),
    value: RevisionType.Character,
    isDisabled: [
      RELEASE_STATUS_BUILD.VERIFY_UNDER_REVIEW,
      RELEASE_STATUS_BUILD.VERIFY_REVIEW_COMPLETED,
      RELEASE_STATUS_BUILD.COMING_SOON
    ].includes(buildDetail.value?.releaseStatus || '')
  }
]);

const handleUsageTiersClick = async () => {
  if (
    !(await checkProductPermission({
      checkType: 'read',
      roleId: ROLE_ID.PRODUCT_RATING_BUILD_VERIFICATION
    }))
  ) {
    return;
  }

  if (
    !(await checkProductPermission({
      checkType: 'write',
      roleId: ROLE_ID.PRODUCT_RATING_BUILD_VERIFICATION
    }))
  ) {
    return;
  }

  // https://jira.smilegate.net/browse/INDIA-21158
  const checkRating = await fetchRatingsProductsApi(productId);

  // Check if rating revision is in progress
  if (checkRating &&
          checkRating.requestRating &&
          checkRating.requestRating.type === RatingTypeStatus.GAME_REVISION &&
          checkRating.requestRating.verifyInfo &&
          checkRating.requestRating.verifyInfo.verifyStatus === VerifyStatus.REQUEST) {
    await showAlert({
      content: t('studio.build_rating_review.revision_already_in_progress_msg'),
      confirmLabel: t('studio.common.popup_case_cf_btn')
    });
    return;
  }

  if (buildDetail.value?.releaseStatus === RELEASE_STATUS_BUILD.VERIFY_UNDER_REVIEW) {
    redirectTo(PRODUCT_RATING_URL);
    return;
  }

  const result = (await showDialog({
    component: shallowRef(RatingSurveyDialog),
    props: {
      type: SurveyDialogType.REVISION,
      revisionTypes: selectedProductsId.value.map(mapRevisionTypeToApiValue),
      initialRevisionTypes: selectedProductsId.value,
      mode: RatingPageMode.EDIT,
      buildId
    }
  })) as string;

  if (result) {
    window.location.reload();
  }
};

const handleApplyLive = handleSubmit(async () => {
  if (liveDate.value === Confirmation.NO && dateTimeError.value) {
    return;
  }
  if (meta.value.valid) {
    const gameId = buildDetail.value?.gameId;
    if (!gameId) {
      return;
    }

    const params: ApplyLiveReleaseBuildParams = {
      gameId,
      buildId,
      releaseType:
        liveDate.value === Confirmation.YES
          ? APPLY_LIVE_RELEASE_TYPE.IMMEDIATE
          : APPLY_LIVE_RELEASE_TYPE.SCHEDULED,
      ...(liveDate.value === Confirmation.NO &&
        selectedDate.value && { scheduledStartAt: new Date(selectedDate.value).getTime() })
    };

    const confirmed = await showDialog({
      component: ConfirmReleaseBuild,
      props: {
        class: 'max-w-[31.2rem]'
      },
      severity: 'info'
    });

    if (confirmed) {
      // Check if selected type is RevisionType.BugFix
      const isEtcType =
      selectedProductsId.value.length === 1 && selectedProductsId.value[0] === RevisionType.BugFix;

      if (isEtcType) {
        const revisionParams: PostRatingRevisionProductRequest = {
          language: locale.value,
          results: [],
          usePreviousSurveyAnswer: false,
          version: '',
          buildId,
          productDescription: '',
          productControlDescription: '',
          fileUrls: [],
          referenceDownloadLinks: [],
          revisionContent: '',
          revisionTypes: selectedProductsId.value.map(mapRevisionTypeToApiValue)
        };

        try {
          await postRatingsRevisionProductsApi(productId, revisionParams);
        } catch (err) {
          const error = err as ErrorResponse;
          if (error.statusCode === STATUS_CODE.REVISION_ALREADY_IN_PROGRESS) {
            await showAlert({
              content: t('studio.build_rating_review.revision_already_in_progress_msg'),
              confirmLabel: t('studio.common.popup_case_cf_btn')
            });
            return;
          }
          if (error.statusCode === STATUS_CODE.RATING_REVISION_STATUS_CANNOT_BE_MODIFIED) {
            await showAlert({
              content: t('studio.build_rating_review.revision_already_in_progress_msg'),
              confirmLabel: t('studio.common.popup_case_cf_btn')
            });
            return;
          }

          showCommonErrorDialog(COMMON_ERROR_MESSAGE_KEY);
          return;
        }
      }

      const result = await createApplyLiveReleaseBuildApi(params);

      if (result?.code === API_SUCCESS_STATUS_CODE) {
        // https://sgp-internal.slack.com/archives/C07K2R2CKCL/p1741230454232339
        generateMetaFileApi(gameId);

        await showAlert({
          severity: 'info',
          content: t('studio.common.popup_case_iv_release_guide'),
          confirmLabel: t('studio.common.popup_case_cf_btn'),
          confirmClasses: '!max-w-full !w-full'
        });

        // Refresh the page only if user selected immediate apply option
        if (liveDate.value === Confirmation.YES) {
          window.location.reload();
        }
      } else {
        await showAlert({
          severity: 'info',
          content: t(COMMON_ERROR_MESSAGE_KEY),
          confirmLabel: t('studio.common.popup_case_cf_btn'),
          confirmClasses: '!max-w-full !w-full'
        });
      }

      //  STATUS_CODE.BUILD_SCHEDULE_CAN_NOT_BE_APPLIED : 4933 removed
    }
  }
});

const isLoaded = computed(() => !!applyLiveInfo.value);
const isShowCheckbox = computed(() => {
  return (
    isLoaded.value &&
    applyLiveInfo.value?.directYn !== 'Y' &&
    currentProductDetailType.value !== PRODUCT_TYPE_DETAIL.UTILITY_BASIC &&
    currentProductDetailType.value !== PRODUCT_TYPE_DETAIL.UTILITY_DEMO &&
    currentProductDetailType.value !== PRODUCT_TYPE_DETAIL.UTILITY_DLC
  );
});

const showDatetimeSection = computed(() => {
  if (
    currentProductDetailType.value === PRODUCT_TYPE_DETAIL.UTILITY_BASIC ||
    currentProductDetailType.value === PRODUCT_TYPE_DETAIL.UTILITY_DEMO ||
    currentProductDetailType.value === PRODUCT_TYPE_DETAIL.UTILITY_DLC
  ) {
    return true;
  }

  const isEtcType =
    selectedProductsId.value.length === 1 && selectedProductsId.value[0] === RevisionType.BugFix;
  const isDirectY = applyLiveInfo.value?.directYn === 'Y';
  const isComingSoon =
    buildDetail.value?.releaseStatus === RELEASE_STATUS_BUILD.COMING_SOON ||
    buildDetail.value?.releaseStatus === RELEASE_STATUS_BUILD.VERIFY_REVIEW_COMPLETED;

  return isEtcType || isDirectY || isComingSoon;
});

const isButtonDisabled = computed(() => {
  const hasInvalidStatus =
    !showDatetimeSection.value &&
    buildDetail.value?.releaseStatus !== RELEASE_STATUS_BUILD.VERIFY_REVIEW_COMPLETED;

  const hasDateTimeError = liveDate.value === Confirmation.NO && !!dateTimeError.value;

  return hasInvalidStatus || hasDateTimeError;
});

const showButtonSection = computed(() => {
  if (
    currentProductDetailType.value === PRODUCT_TYPE_DETAIL.UTILITY_BASIC ||
    currentProductDetailType.value === PRODUCT_TYPE_DETAIL.UTILITY_DEMO ||
    currentProductDetailType.value === PRODUCT_TYPE_DETAIL.UTILITY_DLC
  ) {
    return false;
  }

  if (selectedProductsId.value.length === 0) {
    return false;
  }

  if (
    selectedProductsId.value.length === 1 &&
    selectedProductsId.value[0] === RevisionType.BugFix
  ) {
    return false;
  }

  if (
    buildDetail.value?.releaseStatus === RELEASE_STATUS_BUILD.COMING_SOON ||
    buildDetail.value?.releaseStatus === RELEASE_STATUS_BUILD.VERIFY_REVIEW_COMPLETED
  ) {
    return false;
  }

  return showInfoChangeReport.value;
});

init();
</script>
